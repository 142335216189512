import { Typography } from "@mui/material";
import clsx from "clsx";
import { toast } from "react-toastify";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Button, { BUTTON_VARIANTS } from "../../components/Button";
import CodeSnippet, {
  THEME as CodeSnippetTheme,
} from "../../components/CodeSnippet";
import CustomCheckBox, { THEME } from "../../components/CustomCheckBox";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../components/Tab";
import Tooltip from "../../components/Tooltip";
import DocsIcon from "../../icons/DocsIcon";
import InfoIcon from "../../icons/InfoIcon";
import RocketIcon from "../../icons/RocketIcon";
import InstallStep from "./InstallStep";
import { containerClassName, getCommand, TabType } from "./utils";

const FORCE_ENABLED_SELF_HOSTING = false;
const INFO_ICON_SIZE = 14;
const HAS_COPY_BUTTON = false;
const codeSnippetClassName = "w-[43rem] max-[1298px]:w-full";

export enum HostingMethod {
  SelfHostedCluster = "Self-Hosted Cluster",
  hostedCloud = "Hosted Cloud",
}

const HostingMethodDescription = {
  [HostingMethod.hostedCloud]: (
    <>
      We host the dashboard for you on our <b>Hosted Cloud</b>
    </>
  ),
  [HostingMethod.SelfHostedCluster]: (
    <>
      You host the dashboard on your own <b>Self-Hosted Cluster</b>
    </>
  ),
};

const showSuccessToast = () => {
  toast.success("Command copied to clipboard!", {
    position: "top-center",
  });
};

interface Props {
  token: string;
  customerPrefix: string;
  selectedTab: TabType;
  isFreeTrial: boolean;
  subdomain: string;
}

const GuideSection = ({
  token,
  customerPrefix,
  selectedTab,
  isFreeTrial,
  subdomain,
}: Props) => {
  // uncommented this if we decide to add the tab design for the install page
  // const hasHostingOptionsEnabled =
  //     FORCE_ENABLED_SELF_HOSTING || (subdomain && subdomain !== "");
  const hasHostingOptionsEnabled = false;

  const [selectedHostingMethod, setSelectedHostingMethod] = useQueryParam(
    "hostingMethod",
    withDefault(StringParam, HostingMethod.SelfHostedCluster),
  );

  const [hasOpenShift, setHasOpenShift] = useQueryParam(
    "useOpenshift",
    withDefault(StringParam, "false"),
  );

  return (
    <>
      <div className="relative">
        <div className="max-[1107px]:hidden">
          <video
            src="https://scaleops.com/wp-content/uploads/2024/05/Scaleops-Dashboard.mp4"
            style={{
              width: "35%",
              position: "absolute",
              zIndex: 1,
              top: "50%",
              right: "0%",
              transform: "translate(-5%, -50%)",
              border: "5px solid #CBD5E0",
              borderRadius: "20px",
            }}
            className="shadow-2xl"
            muted
            autoPlay
            loop
          />
        </div>

        {hasHostingOptionsEnabled && (
          <div className={clsx(TABS_CONTAINER_CLASS_NAME)}>
            {Object.entries(HostingMethod).map(([key, value]) => {
              const isDisabled =
                value === HostingMethod.hostedCloud && isFreeTrial;
              return (
                <Tab
                  key={key}
                  isSelected={selectedHostingMethod === value}
                  onClick={() => {
                    setSelectedHostingMethod(value as HostingMethod);
                  }}
                  name={
                    <Tooltip
                      title={
                        isDisabled ? (
                          <>
                            Hosted Cloud is not supported in <b>free trial</b>
                          </>
                        ) : (
                          HostingMethodDescription[value]
                        )
                      }
                      className="flex justify-center items-center gap-1"
                      maxWidth={500}
                    >
                      <span>{value}</span>
                      <InfoIcon
                        width={INFO_ICON_SIZE}
                        height={INFO_ICON_SIZE}
                      />
                    </Tooltip>
                  }
                  dataTestId={`installation-method-${value}`}
                  className="border border-black border-b-0"
                  disabled={isDisabled}
                />
              );
            })}
          </div>
        )}
        <div
          className={clsx(
            containerClassName,
            "bg-white border border-1 border-black flex flex-col gap-5 min-h-[470px]",
            { "mt-[35px]": hasHostingOptionsEnabled },
          )}
        >
          {selectedTab === TabType.INSTALL && (
            <InstallStep
              stepNumber={1}
              title={
                <>
                  Run the <b>installation command</b>
                </>
              }
              content={
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <CustomCheckBox
                      value={hasOpenShift === "true"}
                      onClick={(checked: boolean | undefined) => {
                        setHasOpenShift(checked ? "true" : "false");
                      }}
                      theme={THEME.PURPLE}
                    />
                    <Typography variant="body2">OpenShift</Typography>
                  </div>
                  <CodeSnippet
                    theme={CodeSnippetTheme.light}
                    codeSnippet={getCommand({
                      commandType: "install",
                      customerPrefix,
                      hasOpenShift: hasOpenShift === "true",
                      showToken: false,
                      isFreeTrial: isFreeTrial,
                      token,
                      isHostedCloud:
                        selectedHostingMethod === HostingMethod.hostedCloud,
                    })}
                    codeSnippetToCopy={getCommand({
                      commandType: "install",
                      customerPrefix,
                      hasOpenShift: hasOpenShift === "true",
                      showToken: true,
                      isFreeTrial: isFreeTrial,
                      token,
                      isHostedCloud:
                        selectedHostingMethod === HostingMethod.hostedCloud,
                    })}
                    className={codeSnippetClassName}
                  />
                  {HAS_COPY_BUTTON && (
                    <Button
                      label="COPY"
                      variant={BUTTON_VARIANTS.mediumDarkPurple}
                      onClick={() => {
                        let codeToCopy = getCommand({
                          commandType: "install",
                          customerPrefix,
                          hasOpenShift: hasOpenShift === "true",
                          showToken: true,
                          isFreeTrial: isFreeTrial,
                          token,
                        });
                        codeToCopy = codeToCopy.trim();
                        navigator.clipboard.writeText(codeToCopy);
                        showSuccessToast();
                      }}
                      fontSize={14}
                    />
                  )}
                </div>
              }
            />
          )}
          {selectedTab === TabType.UPGRADE && (
            <InstallStep
              stepNumber={1}
              title={
                <>
                  Run the <b>upgrade command</b>
                </>
              }
              content={
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <CustomCheckBox
                      value={hasOpenShift === "true"}
                      onClick={(checked: boolean | undefined) => {
                        setHasOpenShift(checked ? "true" : "false");
                      }}
                      theme={THEME.PURPLE}
                    />
                    <Typography variant="body2">OpenShift</Typography>
                  </div>
                  <CodeSnippet
                    theme={CodeSnippetTheme.light}
                    codeSnippet={getCommand({
                      commandType: "upgrade",
                      hasOpenShift: hasOpenShift === "true",
                      token,
                      isFreeTrial: isFreeTrial,
                    })}
                    codeSnippetToCopy={getCommand({
                      commandType: "upgrade",
                      hasOpenShift: hasOpenShift === "true",
                      token,
                      showToken: true,
                      isFreeTrial: isFreeTrial,
                    })}
                    className={codeSnippetClassName}
                  />
                  {HAS_COPY_BUTTON && (
                    <Button
                      label="COPY"
                      variant={BUTTON_VARIANTS.mediumDarkPurple}
                      onClick={() => {
                        let codeToCopy = getCommand({
                          commandType: "upgrade",
                          token,
                          hasOpenShift: hasOpenShift === "true",
                          showToken: true,
                          isFreeTrial: isFreeTrial,
                        });
                        codeToCopy = codeToCopy.trim();
                        navigator.clipboard.writeText(codeToCopy);
                        showSuccessToast();
                      }}
                      fontSize={14}
                    />
                  )}
                </div>
              }
            />
          )}
          {selectedHostingMethod === HostingMethod.hostedCloud ? (
            <>
              <InstallStep
                stepNumber={2}
                title={
                  <b>
                    Get Started!{" "}
                    <span className="text-primary-purpleBlue">
                      Optimize your cluster
                    </span>
                  </b>
                }
                content={
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <RocketIcon />
                      <Typography variant="body1" fontWeight={700}>
                        <span>
                          Browse to{" "}
                          <a
                            className="text-primary-purpleBlue underline hover:text-main-blue"
                            href={`https://${subdomain}.scaleops.com`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`https://${subdomain}.scaleops.com`}
                          </a>
                        </span>
                      </Typography>
                    </div>
                  </div>
                }
              />
            </>
          ) : (
            <>
              <InstallStep
                stepNumber={2}
                title="Enable Port-Forward"
                content={
                  <div className="flex flex-col gap-2">
                    <CodeSnippet
                      theme={CodeSnippetTheme.light}
                      codeSnippet={getCommand({
                        commandType: "portForward",
                        hasOpenShift: hasOpenShift === "true",
                        showToken: false,
                        isFreeTrial: isFreeTrial,
                        token,
                      })}
                      className={codeSnippetClassName}
                    />
                  </div>
                }
              />
              <InstallStep
                stepNumber={3}
                title={
                  <b>
                    Get Started!{" "}
                    <span className="text-primary-purpleBlue">
                      Optimize your cluster
                    </span>
                  </b>
                }
                content={
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <RocketIcon />
                      <Typography variant="body1" fontWeight={700}>
                        <span>
                          Browse to{" "}
                          <a
                            className="text-primary-purpleBlue underline hover:text-main-blue"
                            href="http://localhost:8080"
                            target="_blank"
                            rel="noreferrer"
                          >
                            http://localhost:8080
                          </a>
                        </span>
                      </Typography>
                    </div>
                  </div>
                }
              />
            </>
          )}
          <a
            className="absolute max-[1298px]:relative bottom-6 max-[1298px]:bottom-0  right-6 max-[1298px]:right-0  p-2 text-white bg-primary-purpleBlue hover:bg-main-blue rounded-lg scaleopsShadow border border-black"
            href={"https://docs.scaleops.com?token=" + token}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="caption" className="flex gap-2 items-center">
              <DocsIcon width={16} height={16} />
              <span>Documentation</span>
            </Typography>
          </a>
        </div>
      </div>
    </>
  );
};

export default GuideSection;
