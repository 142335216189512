/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { Client } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ClientUpdateForm(props) {
  const {
    id: idProp,
    client: clientModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    githubToken: "",
    scaleOpsToken: "",
    isScaleOpsDev: false,
    useScaleOpsToken: false,
    url: "",
    metricsVerbosity: "",
    isTrialExpired: false,
    isDeleted: false,
    status: "",
    firstInstallDate: "",
    salesForceAccountUrl: "",
    accountExecutive: "",
    accountExecutiveName: "",
    accountExecutiveSlackHandle: "",
    solutionsEngineer: "",
    solutionsEngineerName: "",
    solutionsEngineerSlackHandle: "",
    vpId: "",
    vpName: "",
    vpSlackHandle: "",
    companySize: "",
    linkedInUrl: "",
    firstClusterRegion: "",
    subdomain: "",
    emailDomains: [],
    adminEmails: [],
  };
  const [name, setName] = React.useState(initialValues.name);
  const [githubToken, setGithubToken] = React.useState(
    initialValues.githubToken,
  );
  const [scaleOpsToken, setScaleOpsToken] = React.useState(
    initialValues.scaleOpsToken,
  );
  const [isScaleOpsDev, setIsScaleOpsDev] = React.useState(
    initialValues.isScaleOpsDev,
  );
  const [useScaleOpsToken, setUseScaleOpsToken] = React.useState(
    initialValues.useScaleOpsToken,
  );
  const [url, setUrl] = React.useState(initialValues.url);
  const [metricsVerbosity, setMetricsVerbosity] = React.useState(
    initialValues.metricsVerbosity,
  );
  const [isTrialExpired, setIsTrialExpired] = React.useState(
    initialValues.isTrialExpired,
  );
  const [isDeleted, setIsDeleted] = React.useState(initialValues.isDeleted);
  const [status, setStatus] = React.useState(initialValues.status);
  const [firstInstallDate, setFirstInstallDate] = React.useState(
    initialValues.firstInstallDate,
  );
  const [salesForceAccountUrl, setSalesForceAccountUrl] = React.useState(
    initialValues.salesForceAccountUrl,
  );
  const [accountExecutive, setAccountExecutive] = React.useState(
    initialValues.accountExecutive,
  );
  const [accountExecutiveName, setAccountExecutiveName] = React.useState(
    initialValues.accountExecutiveName,
  );
  const [accountExecutiveSlackHandle, setAccountExecutiveSlackHandle] =
    React.useState(initialValues.accountExecutiveSlackHandle);
  const [solutionsEngineer, setSolutionsEngineer] = React.useState(
    initialValues.solutionsEngineer,
  );
  const [solutionsEngineerName, setSolutionsEngineerName] = React.useState(
    initialValues.solutionsEngineerName,
  );
  const [solutionsEngineerSlackHandle, setSolutionsEngineerSlackHandle] =
    React.useState(initialValues.solutionsEngineerSlackHandle);
  const [vpId, setVpId] = React.useState(initialValues.vpId);
  const [vpName, setVpName] = React.useState(initialValues.vpName);
  const [vpSlackHandle, setVpSlackHandle] = React.useState(
    initialValues.vpSlackHandle,
  );
  const [companySize, setCompanySize] = React.useState(
    initialValues.companySize,
  );
  const [linkedInUrl, setLinkedInUrl] = React.useState(
    initialValues.linkedInUrl,
  );
  const [firstClusterRegion, setFirstClusterRegion] = React.useState(
    initialValues.firstClusterRegion,
  );
  const [subdomain, setSubdomain] = React.useState(initialValues.subdomain);
  const [emailDomains, setEmailDomains] = React.useState(
    initialValues.emailDomains,
  );
  const [adminEmails, setAdminEmails] = React.useState(
    initialValues.adminEmails,
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = clientRecord
      ? { ...initialValues, ...clientRecord }
      : initialValues;
    setName(cleanValues.name);
    setGithubToken(cleanValues.githubToken);
    setScaleOpsToken(cleanValues.scaleOpsToken);
    setIsScaleOpsDev(cleanValues.isScaleOpsDev);
    setUseScaleOpsToken(cleanValues.useScaleOpsToken);
    setUrl(cleanValues.url);
    setMetricsVerbosity(cleanValues.metricsVerbosity);
    setIsTrialExpired(cleanValues.isTrialExpired);
    setIsDeleted(cleanValues.isDeleted);
    setStatus(cleanValues.status);
    setFirstInstallDate(cleanValues.firstInstallDate);
    setSalesForceAccountUrl(cleanValues.salesForceAccountUrl);
    setAccountExecutive(cleanValues.accountExecutive);
    setAccountExecutiveName(cleanValues.accountExecutiveName);
    setAccountExecutiveSlackHandle(cleanValues.accountExecutiveSlackHandle);
    setSolutionsEngineer(cleanValues.solutionsEngineer);
    setSolutionsEngineerName(cleanValues.solutionsEngineerName);
    setSolutionsEngineerSlackHandle(cleanValues.solutionsEngineerSlackHandle);
    setVpId(cleanValues.vpId);
    setVpName(cleanValues.vpName);
    setVpSlackHandle(cleanValues.vpSlackHandle);
    setCompanySize(cleanValues.companySize);
    setLinkedInUrl(cleanValues.linkedInUrl);
    setFirstClusterRegion(cleanValues.firstClusterRegion);
    setSubdomain(cleanValues.subdomain);
    setEmailDomains(cleanValues.emailDomains ?? []);
    setCurrentEmailDomainsValue("");
    setAdminEmails(cleanValues.adminEmails ?? []);
    setCurrentAdminEmailsValue("");
    setErrors({});
  };
  const [clientRecord, setClientRecord] = React.useState(clientModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Client, idProp)
        : clientModelProp;
      setClientRecord(record);
    };
    queryData();
  }, [idProp, clientModelProp]);
  React.useEffect(resetStateValues, [clientRecord]);
  const [currentEmailDomainsValue, setCurrentEmailDomainsValue] =
    React.useState("");
  const emailDomainsRef = React.createRef();
  const [currentAdminEmailsValue, setCurrentAdminEmailsValue] =
    React.useState("");
  const adminEmailsRef = React.createRef();
  const validations = {
    name: [{ type: "Required" }],
    githubToken: [{ type: "Required" }],
    scaleOpsToken: [],
    isScaleOpsDev: [],
    useScaleOpsToken: [],
    url: [{ type: "Required" }],
    metricsVerbosity: [],
    isTrialExpired: [],
    isDeleted: [],
    status: [],
    firstInstallDate: [],
    salesForceAccountUrl: [],
    accountExecutive: [],
    accountExecutiveName: [],
    accountExecutiveSlackHandle: [],
    solutionsEngineer: [],
    solutionsEngineerName: [],
    solutionsEngineerSlackHandle: [],
    vpId: [],
    vpName: [],
    vpSlackHandle: [],
    companySize: [],
    linkedInUrl: [],
    firstClusterRegion: [],
    subdomain: [],
    emailDomains: [],
    adminEmails: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue,
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          githubToken,
          scaleOpsToken,
          isScaleOpsDev,
          useScaleOpsToken,
          url,
          metricsVerbosity,
          isTrialExpired,
          isDeleted,
          status,
          firstInstallDate,
          salesForceAccountUrl,
          accountExecutive,
          accountExecutiveName,
          accountExecutiveSlackHandle,
          solutionsEngineer,
          solutionsEngineerName,
          solutionsEngineerSlackHandle,
          vpId,
          vpName,
          vpSlackHandle,
          companySize,
          linkedInUrl,
          firstClusterRegion,
          subdomain,
          emailDomains,
          adminEmails,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item),
                ),
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName]),
            );
            return promises;
          }, []),
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Client.copyOf(clientRecord, (updated) => {
              Object.assign(updated, modelFields);
            }),
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClientUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Github token"
        isRequired={true}
        isReadOnly={false}
        value={githubToken}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken: value,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.githubToken ?? value;
          }
          if (errors.githubToken?.hasError) {
            runValidationTasks("githubToken", value);
          }
          setGithubToken(value);
        }}
        onBlur={() => runValidationTasks("githubToken", githubToken)}
        errorMessage={errors.githubToken?.errorMessage}
        hasError={errors.githubToken?.hasError}
        {...getOverrideProps(overrides, "githubToken")}
      ></TextField>
      <TextField
        label="Scale ops token"
        isRequired={false}
        isReadOnly={false}
        value={scaleOpsToken}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken: value,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.scaleOpsToken ?? value;
          }
          if (errors.scaleOpsToken?.hasError) {
            runValidationTasks("scaleOpsToken", value);
          }
          setScaleOpsToken(value);
        }}
        onBlur={() => runValidationTasks("scaleOpsToken", scaleOpsToken)}
        errorMessage={errors.scaleOpsToken?.errorMessage}
        hasError={errors.scaleOpsToken?.hasError}
        {...getOverrideProps(overrides, "scaleOpsToken")}
      ></TextField>
      <SwitchField
        label="Is scale ops dev"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isScaleOpsDev}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev: value,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.isScaleOpsDev ?? value;
          }
          if (errors.isScaleOpsDev?.hasError) {
            runValidationTasks("isScaleOpsDev", value);
          }
          setIsScaleOpsDev(value);
        }}
        onBlur={() => runValidationTasks("isScaleOpsDev", isScaleOpsDev)}
        errorMessage={errors.isScaleOpsDev?.errorMessage}
        hasError={errors.isScaleOpsDev?.hasError}
        {...getOverrideProps(overrides, "isScaleOpsDev")}
      ></SwitchField>
      <SwitchField
        label="Use scale ops token"
        defaultChecked={false}
        isDisabled={false}
        isChecked={useScaleOpsToken}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken: value,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.useScaleOpsToken ?? value;
          }
          if (errors.useScaleOpsToken?.hasError) {
            runValidationTasks("useScaleOpsToken", value);
          }
          setUseScaleOpsToken(value);
        }}
        onBlur={() => runValidationTasks("useScaleOpsToken", useScaleOpsToken)}
        errorMessage={errors.useScaleOpsToken?.errorMessage}
        hasError={errors.useScaleOpsToken?.hasError}
        {...getOverrideProps(overrides, "useScaleOpsToken")}
      ></SwitchField>
      <TextField
        label="Url"
        isRequired={true}
        isReadOnly={false}
        value={url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url: value,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.url ?? value;
          }
          if (errors.url?.hasError) {
            runValidationTasks("url", value);
          }
          setUrl(value);
        }}
        onBlur={() => runValidationTasks("url", url)}
        errorMessage={errors.url?.errorMessage}
        hasError={errors.url?.hasError}
        {...getOverrideProps(overrides, "url")}
      ></TextField>
      <SelectField
        label="Metrics verbosity"
        placeholder="Please select an option"
        isDisabled={false}
        value={metricsVerbosity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity: value,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.metricsVerbosity ?? value;
          }
          if (errors.metricsVerbosity?.hasError) {
            runValidationTasks("metricsVerbosity", value);
          }
          setMetricsVerbosity(value);
        }}
        onBlur={() => runValidationTasks("metricsVerbosity", metricsVerbosity)}
        errorMessage={errors.metricsVerbosity?.errorMessage}
        hasError={errors.metricsVerbosity?.hasError}
        {...getOverrideProps(overrides, "metricsVerbosity")}
      >
        <option
          children="Keep"
          value="KEEP"
          {...getOverrideProps(overrides, "metricsVerbosityoption0")}
        ></option>
        <option
          children="None"
          value="NONE"
          {...getOverrideProps(overrides, "metricsVerbosityoption1")}
        ></option>
        <option
          children="Minimal"
          value="MINIMAL"
          {...getOverrideProps(overrides, "metricsVerbosityoption2")}
        ></option>
        <option
          children="Poc"
          value="POC"
          {...getOverrideProps(overrides, "metricsVerbosityoption3")}
        ></option>
        <option
          children="All"
          value="ALL"
          {...getOverrideProps(overrides, "metricsVerbosityoption4")}
        ></option>
      </SelectField>
      <SwitchField
        label="Is trial expired"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isTrialExpired}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired: value,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.isTrialExpired ?? value;
          }
          if (errors.isTrialExpired?.hasError) {
            runValidationTasks("isTrialExpired", value);
          }
          setIsTrialExpired(value);
        }}
        onBlur={() => runValidationTasks("isTrialExpired", isTrialExpired)}
        errorMessage={errors.isTrialExpired?.errorMessage}
        hasError={errors.isTrialExpired?.hasError}
        {...getOverrideProps(overrides, "isTrialExpired")}
      ></SwitchField>
      <SwitchField
        label="Is deleted"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isDeleted}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted: value,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.isDeleted ?? value;
          }
          if (errors.isDeleted?.hasError) {
            runValidationTasks("isDeleted", value);
          }
          setIsDeleted(value);
        }}
        onBlur={() => runValidationTasks("isDeleted", isDeleted)}
        errorMessage={errors.isDeleted?.errorMessage}
        hasError={errors.isDeleted?.hasError}
        {...getOverrideProps(overrides, "isDeleted")}
      ></SwitchField>
      <SelectField
        label="Status"
        placeholder="Please select an option"
        isDisabled={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status: value,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      >
        <option
          children="Free trial"
          value="FreeTrial"
          {...getOverrideProps(overrides, "statusoption0")}
        ></option>
        <option
          children="Premium"
          value="Premium"
          {...getOverrideProps(overrides, "statusoption1")}
        ></option>
        <option
          children="Pause"
          value="Pause"
          {...getOverrideProps(overrides, "statusoption2")}
        ></option>
        <option
          children="Terminated"
          value="Terminated"
          {...getOverrideProps(overrides, "statusoption3")}
        ></option>
      </SelectField>
      <TextField
        label="First install date"
        isRequired={false}
        isReadOnly={false}
        type="datetime-local"
        value={firstInstallDate && convertToLocal(new Date(firstInstallDate))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate: value,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.firstInstallDate ?? value;
          }
          if (errors.firstInstallDate?.hasError) {
            runValidationTasks("firstInstallDate", value);
          }
          setFirstInstallDate(value);
        }}
        onBlur={() => runValidationTasks("firstInstallDate", firstInstallDate)}
        errorMessage={errors.firstInstallDate?.errorMessage}
        hasError={errors.firstInstallDate?.hasError}
        {...getOverrideProps(overrides, "firstInstallDate")}
      ></TextField>
      <TextField
        label="Sales force account url"
        isRequired={false}
        isReadOnly={false}
        value={salesForceAccountUrl}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl: value,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.salesForceAccountUrl ?? value;
          }
          if (errors.salesForceAccountUrl?.hasError) {
            runValidationTasks("salesForceAccountUrl", value);
          }
          setSalesForceAccountUrl(value);
        }}
        onBlur={() =>
          runValidationTasks("salesForceAccountUrl", salesForceAccountUrl)
        }
        errorMessage={errors.salesForceAccountUrl?.errorMessage}
        hasError={errors.salesForceAccountUrl?.hasError}
        {...getOverrideProps(overrides, "salesForceAccountUrl")}
      ></TextField>
      <TextField
        label="Account executive"
        isRequired={false}
        isReadOnly={false}
        value={accountExecutive}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive: value,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.accountExecutive ?? value;
          }
          if (errors.accountExecutive?.hasError) {
            runValidationTasks("accountExecutive", value);
          }
          setAccountExecutive(value);
        }}
        onBlur={() => runValidationTasks("accountExecutive", accountExecutive)}
        errorMessage={errors.accountExecutive?.errorMessage}
        hasError={errors.accountExecutive?.hasError}
        {...getOverrideProps(overrides, "accountExecutive")}
      ></TextField>
      <TextField
        label="Account executive name"
        isRequired={false}
        isReadOnly={false}
        value={accountExecutiveName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName: value,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.accountExecutiveName ?? value;
          }
          if (errors.accountExecutiveName?.hasError) {
            runValidationTasks("accountExecutiveName", value);
          }
          setAccountExecutiveName(value);
        }}
        onBlur={() =>
          runValidationTasks("accountExecutiveName", accountExecutiveName)
        }
        errorMessage={errors.accountExecutiveName?.errorMessage}
        hasError={errors.accountExecutiveName?.hasError}
        {...getOverrideProps(overrides, "accountExecutiveName")}
      ></TextField>
      <TextField
        label="Account executive slack handle"
        isRequired={false}
        isReadOnly={false}
        value={accountExecutiveSlackHandle}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle: value,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.accountExecutiveSlackHandle ?? value;
          }
          if (errors.accountExecutiveSlackHandle?.hasError) {
            runValidationTasks("accountExecutiveSlackHandle", value);
          }
          setAccountExecutiveSlackHandle(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "accountExecutiveSlackHandle",
            accountExecutiveSlackHandle,
          )
        }
        errorMessage={errors.accountExecutiveSlackHandle?.errorMessage}
        hasError={errors.accountExecutiveSlackHandle?.hasError}
        {...getOverrideProps(overrides, "accountExecutiveSlackHandle")}
      ></TextField>
      <TextField
        label="Solutions engineer"
        isRequired={false}
        isReadOnly={false}
        value={solutionsEngineer}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer: value,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.solutionsEngineer ?? value;
          }
          if (errors.solutionsEngineer?.hasError) {
            runValidationTasks("solutionsEngineer", value);
          }
          setSolutionsEngineer(value);
        }}
        onBlur={() =>
          runValidationTasks("solutionsEngineer", solutionsEngineer)
        }
        errorMessage={errors.solutionsEngineer?.errorMessage}
        hasError={errors.solutionsEngineer?.hasError}
        {...getOverrideProps(overrides, "solutionsEngineer")}
      ></TextField>
      <TextField
        label="Solutions engineer name"
        isRequired={false}
        isReadOnly={false}
        value={solutionsEngineerName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName: value,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.solutionsEngineerName ?? value;
          }
          if (errors.solutionsEngineerName?.hasError) {
            runValidationTasks("solutionsEngineerName", value);
          }
          setSolutionsEngineerName(value);
        }}
        onBlur={() =>
          runValidationTasks("solutionsEngineerName", solutionsEngineerName)
        }
        errorMessage={errors.solutionsEngineerName?.errorMessage}
        hasError={errors.solutionsEngineerName?.hasError}
        {...getOverrideProps(overrides, "solutionsEngineerName")}
      ></TextField>
      <TextField
        label="Solutions engineer slack handle"
        isRequired={false}
        isReadOnly={false}
        value={solutionsEngineerSlackHandle}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle: value,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.solutionsEngineerSlackHandle ?? value;
          }
          if (errors.solutionsEngineerSlackHandle?.hasError) {
            runValidationTasks("solutionsEngineerSlackHandle", value);
          }
          setSolutionsEngineerSlackHandle(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "solutionsEngineerSlackHandle",
            solutionsEngineerSlackHandle,
          )
        }
        errorMessage={errors.solutionsEngineerSlackHandle?.errorMessage}
        hasError={errors.solutionsEngineerSlackHandle?.hasError}
        {...getOverrideProps(overrides, "solutionsEngineerSlackHandle")}
      ></TextField>
      <TextField
        label="Vp id"
        isRequired={false}
        isReadOnly={false}
        value={vpId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId: value,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.vpId ?? value;
          }
          if (errors.vpId?.hasError) {
            runValidationTasks("vpId", value);
          }
          setVpId(value);
        }}
        onBlur={() => runValidationTasks("vpId", vpId)}
        errorMessage={errors.vpId?.errorMessage}
        hasError={errors.vpId?.hasError}
        {...getOverrideProps(overrides, "vpId")}
      ></TextField>
      <TextField
        label="Vp name"
        isRequired={false}
        isReadOnly={false}
        value={vpName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName: value,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.vpName ?? value;
          }
          if (errors.vpName?.hasError) {
            runValidationTasks("vpName", value);
          }
          setVpName(value);
        }}
        onBlur={() => runValidationTasks("vpName", vpName)}
        errorMessage={errors.vpName?.errorMessage}
        hasError={errors.vpName?.hasError}
        {...getOverrideProps(overrides, "vpName")}
      ></TextField>
      <TextField
        label="Vp slack handle"
        isRequired={false}
        isReadOnly={false}
        value={vpSlackHandle}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle: value,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.vpSlackHandle ?? value;
          }
          if (errors.vpSlackHandle?.hasError) {
            runValidationTasks("vpSlackHandle", value);
          }
          setVpSlackHandle(value);
        }}
        onBlur={() => runValidationTasks("vpSlackHandle", vpSlackHandle)}
        errorMessage={errors.vpSlackHandle?.errorMessage}
        hasError={errors.vpSlackHandle?.hasError}
        {...getOverrideProps(overrides, "vpSlackHandle")}
      ></TextField>
      <TextField
        label="Company size"
        isRequired={false}
        isReadOnly={false}
        value={companySize}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize: value,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.companySize ?? value;
          }
          if (errors.companySize?.hasError) {
            runValidationTasks("companySize", value);
          }
          setCompanySize(value);
        }}
        onBlur={() => runValidationTasks("companySize", companySize)}
        errorMessage={errors.companySize?.errorMessage}
        hasError={errors.companySize?.hasError}
        {...getOverrideProps(overrides, "companySize")}
      ></TextField>
      <TextField
        label="Linked in url"
        isRequired={false}
        isReadOnly={false}
        value={linkedInUrl}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl: value,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.linkedInUrl ?? value;
          }
          if (errors.linkedInUrl?.hasError) {
            runValidationTasks("linkedInUrl", value);
          }
          setLinkedInUrl(value);
        }}
        onBlur={() => runValidationTasks("linkedInUrl", linkedInUrl)}
        errorMessage={errors.linkedInUrl?.errorMessage}
        hasError={errors.linkedInUrl?.hasError}
        {...getOverrideProps(overrides, "linkedInUrl")}
      ></TextField>
      <TextField
        label="First cluster region"
        isRequired={false}
        isReadOnly={false}
        value={firstClusterRegion}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion: value,
              subdomain,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.firstClusterRegion ?? value;
          }
          if (errors.firstClusterRegion?.hasError) {
            runValidationTasks("firstClusterRegion", value);
          }
          setFirstClusterRegion(value);
        }}
        onBlur={() =>
          runValidationTasks("firstClusterRegion", firstClusterRegion)
        }
        errorMessage={errors.firstClusterRegion?.errorMessage}
        hasError={errors.firstClusterRegion?.hasError}
        {...getOverrideProps(overrides, "firstClusterRegion")}
      ></TextField>
      <TextField
        label="Subdomain"
        isRequired={false}
        isReadOnly={false}
        value={subdomain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain: value,
              emailDomains,
              adminEmails,
            };
            const result = onChange(modelFields);
            value = result?.subdomain ?? value;
          }
          if (errors.subdomain?.hasError) {
            runValidationTasks("subdomain", value);
          }
          setSubdomain(value);
        }}
        onBlur={() => runValidationTasks("subdomain", subdomain)}
        errorMessage={errors.subdomain?.errorMessage}
        hasError={errors.subdomain?.hasError}
        {...getOverrideProps(overrides, "subdomain")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains: values,
              adminEmails,
            };
            const result = onChange(modelFields);
            values = result?.emailDomains ?? values;
          }
          setEmailDomains(values);
          setCurrentEmailDomainsValue("");
        }}
        currentFieldValue={currentEmailDomainsValue}
        label={"Email domains"}
        items={emailDomains}
        hasError={errors?.emailDomains?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("emailDomains", currentEmailDomainsValue)
        }
        errorMessage={errors?.emailDomains?.errorMessage}
        setFieldValue={setCurrentEmailDomainsValue}
        inputFieldRef={emailDomainsRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Email domains"
          isRequired={false}
          isReadOnly={false}
          value={currentEmailDomainsValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.emailDomains?.hasError) {
              runValidationTasks("emailDomains", value);
            }
            setCurrentEmailDomainsValue(value);
          }}
          onBlur={() =>
            runValidationTasks("emailDomains", currentEmailDomainsValue)
          }
          errorMessage={errors.emailDomains?.errorMessage}
          hasError={errors.emailDomains?.hasError}
          ref={emailDomainsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "emailDomains")}
        ></TextField>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isTrialExpired,
              isDeleted,
              status,
              firstInstallDate,
              salesForceAccountUrl,
              accountExecutive,
              accountExecutiveName,
              accountExecutiveSlackHandle,
              solutionsEngineer,
              solutionsEngineerName,
              solutionsEngineerSlackHandle,
              vpId,
              vpName,
              vpSlackHandle,
              companySize,
              linkedInUrl,
              firstClusterRegion,
              subdomain,
              emailDomains,
              adminEmails: values,
            };
            const result = onChange(modelFields);
            values = result?.adminEmails ?? values;
          }
          setAdminEmails(values);
          setCurrentAdminEmailsValue("");
        }}
        currentFieldValue={currentAdminEmailsValue}
        label={"Admin emails"}
        items={adminEmails}
        hasError={errors?.adminEmails?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("adminEmails", currentAdminEmailsValue)
        }
        errorMessage={errors?.adminEmails?.errorMessage}
        setFieldValue={setCurrentAdminEmailsValue}
        inputFieldRef={adminEmailsRef}
        defaultFieldValue={""}
      >
        <TextField
          label="Admin emails"
          isRequired={false}
          isReadOnly={false}
          value={currentAdminEmailsValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.adminEmails?.hasError) {
              runValidationTasks("adminEmails", value);
            }
            setCurrentAdminEmailsValue(value);
          }}
          onBlur={() =>
            runValidationTasks("adminEmails", currentAdminEmailsValue)
          }
          errorMessage={errors.adminEmails?.errorMessage}
          hasError={errors.adminEmails?.hasError}
          ref={adminEmailsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "adminEmails")}
        ></TextField>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || clientModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || clientModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
