import clsx from "clsx";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import ScaleopsIcon from "../../icons/ScaleopsIcon";
import DiscoverBanner from "./DiscoverBanner";
import GuideSection from "./GuideSection";
import Ribbon from "./Ribbon";
import { HAS_STRINGER_TAB } from "./Tab";
import TabsContainer from "./TabsContainer";
import Terms from "./Terms";
import TopPurpleBanner from "./TopPurpleBanner";
import { TabType } from "./utils";

const BackgroundPattern = () => (
  <>
    <ScaleopsIcon
      width={"50vw"}
      height={"50vw"}
      className="position fixed top-[30%] left-0 opacity-10"
    />
    <ScaleopsIcon
      width={"30vw"}
      height={"30vw"}
      className="position fixed top-[40%] right-0 opacity-20"
    />
  </>
);

interface Props {
  token: string;
  customerPrefix: string;
  companyName?: string;
  isFreeTrial: boolean;
  subdomain: string;
}

const InstallAndUpgradePrivateRegistryContainer = ({
  token,
  customerPrefix,
  companyName,
  isFreeTrial,
  subdomain,
}: Props) => {
  const [selectedTab, setSelectedTab] = useQueryParam(
    "kind",
    withDefault(StringParam, TabType.INSTALL),
  ) as [TabType, (newValue: TabType) => void];

  return (
    <div className="w-full h-full relative flex justify-center">
      <div className="bg-main-installBackground fixed top-0 left-0 w-full h-full z-[-1]" />
      <BackgroundPattern />
      <div className="w-[98%] max-w-[85rem] min-w-[78rem] flex flex-col gap-[1rem] py-[1.25rem]">
        {!isFreeTrial ? (
          <div className={clsx({ "my-[-10px]": !HAS_STRINGER_TAB })}>
            <TabsContainer
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="w-[98%] max-w-[85rem] min-w-[78rem] flex flex-col gap-[1rem] py-[1.25rem] relative">
          <TopPurpleBanner
            selectedTab={selectedTab}
            companyName={companyName}
          />
          <Ribbon
            isFreeTrial={isFreeTrial}
            className="position absolute top-[-53px] right-[-107px] z-50"
            width={450}
          />
          <DiscoverBanner />
          <GuideSection
            token={token}
            customerPrefix={customerPrefix}
            selectedTab={selectedTab}
            isFreeTrial={true}
            subdomain={subdomain}
          />
        </div>
        <Terms />
      </div>
    </div>
  );
};
export default InstallAndUpgradePrivateRegistryContainer;
