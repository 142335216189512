import { CircularProgress, Typography } from "@mui/material";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Client, Status } from "../API";
import awsConfig from "../aws-exports";
import InstallGuide from "../components/InstallGuide";
import { getClientByHashName } from "../graphql/queries";
import InstallAndUpgradeContainer from "./InstallAndUpgrade/InstallAndUpgradeContainer";
import InstallAndUpgradeTrailContainer from "./InstallAndUpgrade/InstallAndUpgradePrivateRegistryContainer";

export const HAS_NEW_LOGIN_PAGE = true;

export default function CompanyPage() {
  let { companyName, sha } = useParams();
  const [company, setCompany] = useState<Client | undefined>(undefined);

  useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  async function fetchCompany() {
    if (companyName !== undefined && sha !== undefined) {
      const response = (await API.graphql(
        graphqlOperation(getClientByHashName, {
          name: companyName?.toLowerCase(),
          hash: sha?.toLowerCase(),
        }),
      )) as { data: { getClientByHashName: Client } };
      setCompany(response.data.getClientByHashName);
    } else {
      return Promise.resolve();
    }
  }

  const fetchCompanyProp = useCallback(fetchCompany, [companyName, sha]);

  useEffect(() => {
    fetchCompanyProp()
      .then(() => console.log("ready"))
      .catch((reason) => console.error(reason));
  }, [fetchCompanyProp]);

  if (!company)
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <div className="flex flex-col justify-center items-center gap-2">
          <CircularProgress color={"secondary"} size={50} />
          <Typography>Loading ...</Typography>
        </div>
      </div>
    );

  const getToken = (): string => {
    if (company) {
      return company.useScaleOpsToken && company.scaleOpsToken
        ? company.scaleOpsToken
        : company.githubToken;
    }
    return "";
  };

  const getContainer = () => {
    if (company.useScaleOpsToken) {
      return (
        <InstallAndUpgradeTrailContainer
          token={getToken()}
          customerPrefix={company.name.toLowerCase().split(" ").join("-")}
          companyName={company.name}
          isFreeTrial={company.status === Status.FreeTrial}
          subdomain={company.subdomain || ""}
        />
      );
    } else {
      return (
        <InstallAndUpgradeContainer
          token={getToken()}
          customerPrefix={company.name.toLowerCase().split(" ").join("-")}
          companyName={company.name}
          subdomain={company.subdomain || ""}
        />
      );
    }
  };

  return HAS_NEW_LOGIN_PAGE ? (
    getContainer()
  ) : (
    <InstallGuide
      companyName={company.name}
      token={getToken()}
      customerPrefix={company.name.toLowerCase().split(" ").join("-")}
    />
  );
}
